// routes
import Router from 'src/routes'
// theme
import ThemeProvider from 'src/theme'
// components
import MotionLazyContainer from 'src/components/animate/MotionLazyContainer'
import NotistackProvider from 'src/components/NotistackProvider'

// ----------------------------------------------------------------------

export default function App() {
  return (
    <MotionLazyContainer>
      <ThemeProvider>
        <NotistackProvider>
          <Router />
        </NotistackProvider>
      </ThemeProvider>
    </MotionLazyContainer>
  )
}

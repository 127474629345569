import React, {FormEvent, useCallback, useEffect, useState} from 'react'
import {
  Box,
  CircularProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  styled,
  TextField,
  Typography
} from '@mui/material'
import config from 'src/config'
import Lottery from 'src/entities/Lottery'
import LotteryWrapper from 'src/components/shl/lottery/LotteryWrapper'
import EmployeeToEvent from 'src/entities/EmployeeToEvent'
import {useSnackbar} from 'notistack'
import CuiProgressButton from 'src/components/custom/CuiProgressButton'
import EmployeeToLottery from 'src/entities/EmployeeToLottery'
import Event from 'src/entities/Event'
import {useAuth} from 'src/contexts/Auth'
import BlockedPage from 'src/components/shl/lottery/BlockedPage'
import isDateAfterToday from 'src/utils/dateHelper'
import {StatusSuccessPage} from 'src/settings/type'
import LotterySuccessPage from 'src/components/shl/lottery/LotterySuccessPage'
import 'src/components/shl/lottery/rfl.css'

const LOTTERY_TYPE_ID_LIST = [1, 2, 4]

const FixedBoxStyle = styled(Box)(() => ({
  width: 151,
  height: 130,
  padding: 10,
  border: '3px solid #fff',
  borderRadius: 5,
  textAlign: 'center',
  position: 'fixed',
  top: '65%',
  color: 'white',
  right: '25%',
  fontSize: 18,
  backgroundColor: '#003f47',
  zIndex: 100,
  fontWeight: 600,
  textTransform: 'uppercase',
  '& .MuiTextField-root': {
    marginBottom: 8,
    marginTop: 5,
    '& #available-tickets-number': {
      height: 13,
      border: 'none',
      fontFamily: 'co-text, sans-serif',
      fontWeight: 700,
      fontStyle: 'normal',
      fontSize: 25,
      color: '#003f47',
      textAlign: 'center',
      background: '#FFF',
      position: 'relative',
      width: '100%',
      borderRadius: 8
    }
  }
}))

export default function LotteriesPage() {
  const [event, setEvent] = useState<Event>()
  const [lotteryList, setLotteryList] = useState<Lottery[]>([])
  const [employeeToEvent, setEmployeeToEvent] = useState<EmployeeToEvent>()
  const [availableTicketsCount, setAvailableTicketsCount] = useState<number>(0)
  const [lotteryTicketsDic, setLotteryTicketsDic] = useState<{
    [key: number]: number | undefined
  }>({})
  const [loading, setLoading] = useState<boolean>()
  const [successPageStatus, setSuccessPageStatus] = useState<StatusSuccessPage>(
    StatusSuccessPage.Hidden
  )

  const {fetchWithUser, employee} = useAuth()
  const {enqueueSnackbar} = useSnackbar()

  const getLotteries = useCallback(
    (eventId: number) => {
      const buildUrl =
        `/LiveLottery/GetLotteriesByEventID/${eventId}?${LOTTERY_TYPE_ID_LIST.map(
          id => `lotteryTypeIDs=${id}&`
        )}`
          .slice(0, -1)
          .replaceAll(',', '')
      fetchWithUser(config.apiUrl + buildUrl)
        .then(res => res.json())
        .then((data: Lottery[]) => {
          setLotteryList(data)
          setLoading(false)
        })
    },
    [fetchWithUser]
  )
  const getEmployeeToLotteryList = useCallback(
    (event: Event) => {
      if (!employee?.employeeID) return
      fetchWithUser(
        config.apiUrl +
          `/LiveLottery/GetEmployeeToLotteriesByEmployeeAndEvent/${employee.employeeID}/${event.eventID}`
      )
        .then(res => res.json())
        .then((data: EmployeeToLottery[]) => {
          const status =
            data.length > 0
              ? event.updatedDate && isDateAfterToday(event.updatedDate)
                ? StatusSuccessPage.EnableUpdate
                : StatusSuccessPage.Closed
              : StatusSuccessPage.Hidden
          setSuccessPageStatus(status)
          const lotteryTicketsDicTemp: {[key: number]: number} = {}
          let ticketsCount = 0
          data.forEach(lte => {
            ticketsCount += lte.ticketsNumber
            lotteryTicketsDicTemp[lte.lotteryID] = lte.ticketsNumber
          })
          setLotteryTicketsDic(lotteryTicketsDicTemp)
          setAvailableTicketsCount(count => count - ticketsCount)
          setLoading(false)
        })
    },
    [employee?.employeeID, fetchWithUser]
  )
  const getEmployeeToLotteryEvent = useCallback(
    (event: Event) => {
      if (!employee?.employeeID) return
      fetchWithUser(
        config.apiUrl +
          `/Event/GetEmployeeToEvent/${event.eventID}/${employee.employeeID}`
      )
        .then(res => res.json())
        .then((data: EmployeeToEvent) => {
          setEmployeeToEvent(data)
          setAvailableTicketsCount(data.ticketsNumber)
          getEmployeeToLotteryList(event)
        })
        .catch(e => {
          if (e.status === 404) {
            setEmployeeToEvent({employeeID: -1, eventID: -1, ticketsNumber: -1})
          } else {
            enqueueSnackbar('Internal server error, Please try again', {
              variant: 'error'
            })
            console.log(e)
          }
        })
    },
    [
      fetchWithUser,
      enqueueSnackbar,
      getEmployeeToLotteryList,
      employee?.employeeID
    ]
  )
  const getCurrentSHLEvent = useCallback(() => {
    setLoading(true)
    fetchWithUser(config.apiUrl + '/Event/GetCurrentSHLEvent')
      .then(res => res.json())
      .then((data: {[key: string]: any}) => {
        const eventData: Event = {
          ...data,
          eventID: data.eventID,
          eventName: data.eventName,
          eventDesc: data.eventDesc,
          eventTypeID: data.eventTypeID,
          updatedDate: data.updatedDate
            ? new Date(data.updatedDate)
            : data.updatedDate
        }
        setEvent(eventData)
        getLotteries(data.eventID)
        getEmployeeToLotteryEvent(eventData)
      })
      .catch(e => {
        setLoading(false)
        enqueueSnackbar('Internal server error, Please try again', {
          variant: 'error'
        })
        console.log(e)
      })
  }, [getLotteries, getEmployeeToLotteryEvent, fetchWithUser, enqueueSnackbar])

  useEffect(getCurrentSHLEvent, [getCurrentSHLEvent])

  const changeLotteryTicketsNumber = (lotteryId: number, value: number) => {
    const currentAvailableTicketsNumber =
      availableTicketsCount -
      (value -
        ((lotteryTicketsDic[lotteryId] ?? 0) > -1
          ? lotteryTicketsDic[lotteryId] ?? 0
          : 0))
    setAvailableTicketsCount(
      currentAvailableTicketsNumber >= 0
        ? currentAvailableTicketsNumber
        : currentAvailableTicketsNumber + value
    )
    setLotteryTicketsDic(lotteryTickets => ({
      ...lotteryTickets,
      [lotteryId]:
        currentAvailableTicketsNumber >= 0
          ? value !== 0
            ? value
            : undefined
          : -1
    }))
  }

  const saveEmployeeToLotteryList = (
    employeeToLotteryList: EmployeeToLottery[]
  ) => {
    return fetchWithUser(
      config.apiUrl + `/LiveLottery/AddEmployeeToLotteries`,
      {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(employeeToLotteryList)
      }
    )
      .then(() => true)
      .catch(e => {
        enqueueSnackbar('Internal server error, Please try again', {
          variant: 'error'
        })
        console.log(e)
        return false
      })
  }

  const submitForm = (form: FormEvent) => {
    form.preventDefault()
    if (!employee) return
    setLoading(true)
    const employeeToLotteryList: EmployeeToLottery[] = []
    Object.entries(lotteryTicketsDic).forEach(([key, value]) => {
      if (value === -1) {
        setLotteryTicketsDic(val => ({...val, [key]: undefined}))
      } else if (value) {
        employeeToLotteryList.push({
          employeeID: employee.employeeID,
          lotteryID: +key,
          ticketsNumber: value,
          officeCollectID: employee.officeID
        })
      }
    })
    if (employeeToLotteryList.length) {
      saveEmployeeToLotteryList(employeeToLotteryList).then(res => {
        if (res) {
          const status =
            !event?.updatedDate || isDateAfterToday(event.updatedDate)
              ? StatusSuccessPage.EnableUpdate
              : StatusSuccessPage.Closed
          setSuccessPageStatus(status)
        }
        setLoading(false)
      })
    } else {
      setLoading(false)
      enqueueSnackbar('Plaese fill out the form before sending it', {
        variant: 'warning'
      })
    }
  }

  if (employeeToEvent && employeeToEvent.ticketsNumber === -1) {
    return <BlockedPage />
  }

  if (successPageStatus) {
    return (
      <LotterySuccessPage
        isEnableUpdate={successPageStatus === StatusSuccessPage.EnableUpdate}
        onClose={() => setSuccessPageStatus(StatusSuccessPage.Hidden)}
      />
    )
  }

  return (
    <Stack bgcolor="primary.main">
      <LotteryWrapper>
        <Stack
          m="auto"
          pt="40px"
          pl="14px"
          minWidth="450px"
          width="98%"
          maxWidth={800}
          spacing={3}
          bgcolor="white"
          pb="25px"
        >
          {!employeeToEvent ? (
            <CircularProgress
              size={48}
              sx={{
                color: 'primary.main',
                position: 'absolute',
                top: '65%',
                left: '50%',
                marginTop: -1.5,
                marginLeft: -1.5
              }}
            />
          ) : (
            <form onSubmit={submitForm}>
              <Stack spacing={3} width={800} maxWidth="98%" m="auto">
                <Stack>
                  {employee && (
                    <>
                      <Typography fontWeight="bold" fontSize="36px">
                        Hello, {employee.firstName} {employee.lastName}
                      </Typography>
                      <Typography fontWeight="bold" fontSize="small">
                        Employee number: {employee.employeeID}
                      </Typography>
                    </>
                  )}
                  <Typography fontWeight="bold" variant="h3">
                    You have {employeeToEvent.ticketsNumber} raffle tickets
                  </Typography>
                  <FixedBoxStyle>
                    <Box>
                      You have
                      <TextField
                        id="available-tickets-number"
                        type="text"
                        disabled
                        value={availableTicketsCount}
                      />
                      tickets left
                    </Box>
                  </FixedBoxStyle>
                </Stack>
                <Stack>
                  <List
                    dense
                    sx={{
                      width: '100%',
                      maxWidth: 800,
                      bgcolor: 'background.paper'
                    }}
                  >
                    {lotteryList.map((lottery, i) => {
                      const labelId = `checkbox-list-secondary-label-${lottery.lotteryID}`
                      return (
                        <ListItem
                          sx={{padding: '10px'}}
                          alignItems="flex-start"
                          key={lottery.lotteryID}
                          disablePadding
                        >
                          <ListItemIcon>
                            <TextField
                              id="outlined-number"
                              type="number"
                              onBlur={() => {
                                if (lotteryTicketsDic[lottery.lotteryID] === -1)
                                  setLotteryTicketsDic(value => ({
                                    ...value,
                                    [lottery.lotteryID]: undefined
                                  }))
                              }}
                              error={
                                lotteryTicketsDic[lottery.lotteryID] === -1
                              }
                              onChange={e =>
                                changeLotteryTicketsNumber(
                                  lottery.lotteryID,
                                  +e.target.value
                                )
                              }
                              value={
                                (lotteryTicketsDic[lottery.lotteryID] ?? 0) < 0
                                  ? ''
                                  : lotteryTicketsDic[lottery.lotteryID] ?? ''
                              }
                              helperText={
                                (lotteryTicketsDic[lottery.lotteryID] ?? 0) <
                                  0 &&
                                'The number you entered is higher than your balance'
                              }
                              sx={{
                                width: '60px',
                                '.MuiFormHelperText-root': {
                                  marginLeft: 0,
                                  width: 300
                                }
                              }}
                              InputProps={{inputProps: {min: 0}}}
                            />
                          </ListItemIcon>
                          <ListItemText
                            id={labelId}
                            sx={{height: 60, marginTop: '8px'}}
                            primary={
                              <label style={{height: 60, fontSize: '15px'}}>
                                <span
                                  style={{
                                    fontWeight: 700,
                                    fontSize: '30px',
                                    textTransform: 'uppercase'
                                  }}
                                >
                                  {i + 1}.{' '}
                                </span>
                                {lottery.lotteryEnglishMame} |{' '}
                                {lottery.lotteryHebrewMame}
                              </label>
                            }
                          />
                        </ListItem>
                      )
                    })}
                  </List>
                </Stack>
                <Stack direction="row" spacing={2} className="bmtBtt">
                  <CuiProgressButton
                    type="submit"
                    variant="contained"
                    m="auto"
                    sx={{
                      background: '#003f47',
                      width: '300px',
                      height: '68px',
                      fontSize: '20px',
                      color: '#fff'
                    }}
                    loading={loading}
                  >
                    Submit
                  </CuiProgressButton>
                </Stack>
              </Stack>
            </form>
          )}
        </Stack>
      </LotteryWrapper>
    </Stack>
  )
}

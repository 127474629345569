import React from 'react'
import {Button, CircularProgress} from '@mui/material'
import {useAuth} from 'src/contexts/Auth'
import {ReactComponent as MicrosoftIcon} from 'src/images/MicrosoftLogo.svg'

export default function LoginButton() {
  const {login, isLoggingIn} = useAuth()

  return (
    <Button
      type="submit"
      variant="outlined"
      onClick={() => login()}
      startIcon={<MicrosoftIcon />}
      endIcon={isLoggingIn && <CircularProgress size={24} />}
      sx={{
        py: 1,
        px: 4,
        mt: 3
      }}
    >
      Continue with Microsoft
    </Button>
  )
}

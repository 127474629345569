import {ReactNode} from 'react'
import {
  alpha,
  ButtonProps,
  LinkProps,
  ListItemButton,
  ListItemIcon,
  styled
} from '@mui/material'
import {ICON, NAVBAR} from 'src/config'

type IProps = LinkProps & ButtonProps

export interface ListItemStyleProps extends IProps {
  component?: ReactNode
  to?: string
  activeRoot?: boolean
  activeSub?: boolean
  subItem?: boolean
  open?: boolean
  roles?: string[]
}

export const ListItemStyle = styled(ListItemButton, {
  shouldForwardProp: prop =>
    prop !== 'activeRoot' && prop !== 'activeSub' && prop !== 'subItem'
})<ListItemStyleProps>(({activeRoot, activeSub, subItem, theme}) => ({
  ...theme.typography.body2,
  position: 'relative',
  height: NAVBAR.DASHBOARD_ITEM_ROOT_HEIGHT,
  textTransform: 'capitalize',
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(1.5),
  marginBottom: theme.spacing(0.5),
  color: theme.palette.text.secondary,
  borderRadius: theme.shape.borderRadius,
  // activeRoot
  ...(activeRoot && {
    ...theme.typography.subtitle2,
    color: '#03787c',
    backgroundColor: alpha('#03787c', theme.palette.action.selectedOpacity)
  }),
  // activeSub
  ...(activeSub && {
    ...theme.typography.subtitle2,
    color: theme.palette.text.primary
  }),
  // subItem
  ...(subItem && {
    height: NAVBAR.DASHBOARD_ITEM_SUB_HEIGHT
  })
}))

export const ListItemIconStyle = styled(ListItemIcon)({
  width: ICON.NAVBAR_ITEM,
  height: ICON.NAVBAR_ITEM,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginInlineEnd: 16,
  marginRight: 0,
  '& svg': {width: '100%', height: '100%'}
})

import React from 'react'
import {Box, Button, styled, Typography} from '@mui/material'
import LotteryWrapper from 'src/components/shl/lottery/LotteryWrapper'
import 'src/components/shl/lottery/rfl.css'
interface Props {
  isEnableUpdate: boolean
  onClose: () => void
}

const TopTextStyle = styled(Typography)(() => ({
  fontWeight: '700',
  color: '#000',
  textAlign: 'center',
  display: 'block'
}))

export default function LotterySuccessPage({isEnableUpdate, onClose}: Props) {
  return (
    <Box className="mainC" height="100%">
      <LotteryWrapper>
        <Box
          textAlign="center"
          position="static"
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            justifyContent: 'space-around',
            height: 'calc(50% - 15px)',
            backgroundColor: '#fff'
          }}
          m="auto"
          pt="30px"
          py="15px"
          minWidth={450}
          width="95%"
          maxWidth={800}
          minHeight={425}
        >
          {isEnableUpdate && (
            <TopTextStyle>
              <Typography component={'span'} pt="10px" fontSize="2em" mb="72px">
                Thank you!
              </Typography>
            </TopTextStyle>
          )}
          <TopTextStyle>
            <Typography component={'span'} pt="10px" fontSize="1.5em" mb="72px">
              {isEnableUpdate
                ? 'Your choice has been submitted successfully.'
                : 'The raffle system is closed'}
            </Typography>
          </TopTextStyle>
          {!isEnableUpdate && (
            <TopTextStyle>
              <Typography component={'span'} pt="10px" fontSize="2em" mb="72px">
                Thank you!
              </Typography>
            </TopTextStyle>
          )}
          {isEnableUpdate && (
            <TopTextStyle>
              <Typography component={'span'} display="block" fontWeight={400}>
                To change your selection please{' '}
                <Button component={'span'} onClick={onClose}>
                  click here
                </Button>
              </Typography>
            </TopTextStyle>
          )}
        </Box>
      </LotteryWrapper>
    </Box>
  )
}

import {Navigate, useRoutes} from 'react-router-dom'
import {Logout} from '@mui/icons-material'
import {AuthContextProvider} from 'src/contexts/Auth'
import RegisterSHLCyclePage from 'src/components/shl/cycle/RegisterSHLCyclePage'
import ProtectedRoute from 'src/components/auth/ProtectedRoutes'
import LoginPage from 'src/components/auth/LoginPage'
import LotteriesPage from 'src/components/shl/lottery/LotteryForm'
import TestList from 'src/components/shl/Test/TestList'

export default function Router() {
  return useRoutes([
    {
      caseSensitive: false,
      path: 'login',
      element: (
        <AuthContextProvider>
          <LoginPage />
        </AuthContextProvider>
      )
    },
    {
      caseSensitive: false,
      path: 'logout',
      element: (
        <AuthContextProvider>
          <Logout />
        </AuthContextProvider>
      )
    },
    {
      caseSensitive: false,
      path: '/registration',
      element: (
        <AuthContextProvider>
          <ProtectedRoute>
            <RegisterSHLCyclePage />
          </ProtectedRoute>
        </AuthContextProvider>
      )
    },
    {
      caseSensitive: false,
      path: '/test',
      element: (
        <AuthContextProvider>
          <ProtectedRoute>
            <TestList />
          </ProtectedRoute>
        </AuthContextProvider>
      )
    },
    {
      caseSensitive: false,
      path: 'raffle',
      element: (
        <AuthContextProvider>
          <ProtectedRoute>
            <LotteriesPage />
          </ProtectedRoute>
        </AuthContextProvider>
      )
    },
    {
      path: '/',
      element: <Navigate replace to={'/test'} />,
      caseSensitive: false
    }
  ])
}

export default interface EmployeeSHLCycleLanguage {
  employeeID?: string
  languageID: number
  examinationTypeID: number
  needSeferID?: NeedSeferEnum
}

export enum NeedSeferEnum {
  NoNeed = 1,
  Need,
  Got
}

import React from 'react'
import {Stack} from '@mui/material'

interface IconAmountColumnProps {
  amount: number | JSX.Element
  onClick?: () => void
  icon?: JSX.Element
  color?: string
}

export default function IconAmountBox({
  amount,
  onClick,
  icon,
  color = 'primary.main'
}: IconAmountColumnProps) {
  return (
    <Stack
      sx={{
        fontSize: 'medium',
        color: color
      }}
      direction="row"
      onClick={onClick}
    >
      {icon}
      {amount}
    </Stack>
  )
}

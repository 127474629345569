import React from 'react'
import 'src/components/shl/lottery/rfl.css'
import footerImg from 'src/images/footer.png'
import topbannerImg from 'src/images/topbanner.png'

const LotteryWrapper = ({children}: any) => (
  <>
    <div className="top">
      <img src={topbannerImg} className="topbanner" alt="header" />
    </div>
    <div className="bigcontainer">
      <div className="container wbg">{children}</div>
    </div>
    <div className="FootB">
      <img src={footerImg} className="Fotbanner" alt="footer" />
    </div>
  </>
)

export default LotteryWrapper

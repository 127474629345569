import React, {useReducer, useContext, useEffect, useCallback} from 'react'
import {OptionsObject, SnackbarKey, SnackbarMessage} from 'notistack'
import config from 'src/config'
import Employee from 'src/entities/Employee'

type EnqueueSnackbar = (
  message: SnackbarMessage,
  options?: OptionsObject | undefined
) => SnackbarKey

interface AuthContextValue {
  user: Employee | null
  logout: () => void
  login: (email: string, enqueueSnackbar: EnqueueSnackbar) => Promise<void>
  isLoggingIn: boolean
  isFetchingUser: boolean
  isAuth: boolean
  roles: string | null
  fetchWithUser: (
    endpoint: RequestInfo,
    options?: RequestInit,
    scopes?: string[]
  ) => Promise<Response>
  loginError: string
  shouldLogout: boolean
}

interface State {
  isAuth: boolean
  roles: string | null
  isLoggingIn: boolean
  isFetchingUser: boolean
  user: Employee | null
  loginError: string
  shouldLogout: boolean
}

type Action =
  | {type: 'DID_LOGOUT'}
  | {type: 'WILL_LOGIN'}
  | {type: 'DID_LOGIN'; data: Employee}
  | {type: 'DID_LOGIN_ERROR'; data: string}
  | {type: 'SHOULD_LOGOUT'; data: string}

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'DID_LOGOUT':
      return {...state, isAuth: false, user: null}
    case 'WILL_LOGIN':
      return {...state, isLoggingIn: true, loginError: ''}
    case 'DID_LOGIN':
      return {
        ...state,
        isAuth: true,
        roles: action.data.employeeID.toString(),
        isFetchingUser: true,
        user: action.data,
        isLoggingIn: false
      }
    case 'DID_LOGIN_ERROR':
      return {
        ...state,
        isLoggingIn: false,
        isAuth: false,
        loginError: action.data
      }
    case 'SHOULD_LOGOUT':
      return {
        ...state,
        shouldLogout: true,
        isAuth: false,
        roles: null,
        isLoggingIn: false
      }
    default:
      return state
  }
}

const initialState: State = {
  isAuth: false,
  roles: null,
  isLoggingIn: false,
  isFetchingUser: false,
  user: null,
  loginError: '',
  shouldLogout: false
}

const AuthContext = React.createContext({} as AuthContextValue)

function AuthContextProvider({children}: any) {
  const employeeId = localStorage.getItem('employeeId')
  const [
    {
      isAuth,
      roles,
      isLoggingIn,
      isFetchingUser,
      user,
      loginError,
      shouldLogout
    },
    dispatch
  ] = useReducer(reducer, {
    ...initialState,
    roles: employeeId,
    isFetchingUser: !!employeeId,
    isAuth: !!employeeId
  })

  const logout = useCallback(() => {
    localStorage.removeItem('employeeId')
    dispatch({type: 'DID_LOGOUT'})
  }, [])

  const fetchWithUser = useCallback(
    async (
      endpoint: RequestInfo,
      options: RequestInit = {}
      // scopes: string[] = scope.api
    ): Promise<Response> => {
      const headers = {
        ...options.headers
        //TODO access with token
        // Authorization: `Bearer ${accessToken}`
      }
      const mergedOpts = {...options, headers}

      return fetch(endpoint, mergedOpts).then(response => {
        if (!response.ok) {
          return Promise.reject(response)
        }
        return response
      })
    },
    []
  )

  const login = useCallback(
    async (email: string, enqueueSnackbar: EnqueueSnackbar) => {
      dispatch({type: 'WILL_LOGIN'})
      try {
        const user: Employee = await fetchWithUser(
          config.apiUrl + `/Employee/GetEmployeeByEmail/${email}`
        )
          .then(res => res.json())
          .catch((e: Response) => {
            if (e.status === 404) {
              throw new Error(e.status.toString())
            } else {
              enqueueSnackbar('Internal server error', {
                variant: 'error'
              })
              throw new Error(e.statusText)
            }
          })
        localStorage.setItem('employeeId', user.employeeID.toString())
        dispatch({type: 'DID_LOGIN', data: user})
      } catch (err: any) {
        if (err.message === '404') throw err
        dispatch({type: 'DID_LOGIN_ERROR', data: err.errorMessage})
      }
    },
    [fetchWithUser]
  )

  const getUserProfile = useCallback(async () => {
    try {
      const user: Employee = await fetchWithUser(
        config.apiUrl + `/Employee/GetEmployee/${employeeId}`
      )
        .then(res => res.json())
        .catch((e: Response) => {
          throw new Error(e.statusText)
        })
      dispatch({type: 'DID_LOGIN', data: user})
    } catch (err: any) {
      dispatch({type: 'SHOULD_LOGOUT', data: err.errorMessage})
    }
  }, [employeeId, fetchWithUser])

  useEffect(() => {
    if (isAuth) getUserProfile()
  }, [isAuth, getUserProfile])

  const value: AuthContextValue = {
    user,
    isAuth,
    roles,
    login,
    logout,
    isLoggingIn,
    isFetchingUser,
    fetchWithUser: fetchWithUser,
    loginError,
    shouldLogout
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

const useAuth = () => useContext(AuthContext)

export {AuthContextProvider, useAuth}
